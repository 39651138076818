var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',[_vm._m(1),_c('button',{staticClass:"bigger",on:{"click":_vm.openzelcoreDeepLink}},[_vm._v(" OPEN IN ZELCORE ")])]),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('br'),_c('br'),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-logo"},[_c('img',{attrs:{"src":require("./assets/img/logo.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("./assets/img/zelcore-round-dark.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"note"},[_c('br'),_vm._v(" Please allow pop up windows for smooth zelcore interaction. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gridThis paddingmobile"},[_c('div',{staticClass:"card"},[_c('a',{attrs:{"href":"https://itunes.apple.com/us/app/zelcore/id1436296839?mt=8","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("./assets/img/appstore.svg")}})])]),_c('div',{staticClass:"card"},[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.zelcash.zelcore","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("./assets/img/googleplay.svg")}})])]),_c('div',{staticClass:"card"},[_c('a',{attrs:{"href":"https://resources.zelcore.io/downloads/zelcore.apk","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("./assets/img/directdownload.svg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gridThis paddingdesktop"},[_c('div',{staticClass:"card"},[_c('a',{attrs:{"href":"https://resources.zelcore.io/downloads/zelcore.dmg","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("./assets/img/macos.png")}})])]),_c('div',{staticClass:"card"},[_c('a',{attrs:{"href":"https://resources.zelcore.io/downloads/zelcore.exe","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("./assets/img/windows.png")}})])]),_c('div',{staticClass:"card"},[_c('a',{attrs:{"href":"https://resources.zelcore.io/downloads/zelcore.deb","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("./assets/img/linux.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('br'),_c('a',{attrs:{"href":"https://zelcore.io","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("zelcore.io")])])
}]

export { render, staticRenderFns }